import React from 'react'
import Navbar from '../../../component/navbar'
import Footer from '../../../component/footer';
import { ToastContainer, toast } from 'react-toastify';

export default function CheckoutOffline() {
    const backend_url =process.env.REACT_APP_BACKEND_URL;
    var validator = require('validator');

    function envoiMessaage(){
        const nom = document.getElementById('name').value;
        const commentaire = document.getElementById('comments').value;
        const email = document.getElementById('email').value;
        const sujet = document.getElementById('subject').value;

        if (!validator.isEmail(email)){
            toast.error("L'adresse mail renseignée n'est pas valide");
        return
       }

        if (nom === "" || commentaire === "" || email === "" || sujet === ""  ){
            return
        }
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            nom: nom,
            commentaire: commentaire,
            email: email,
            sujet: sujet
          
          })};
        
        fetch(backend_url+'/contact/add', requestOptions)
        .then(response => {
            if(response.ok){
                document.getElementById('name').value = "";
                document.getElementById('comments').value = "";
                document.getElementById('email').value = "";
                document.getElementById('subject').value = "";
            }
            return response.json();
        })
        .then( () => {
            toast.success("Votre message a bien été envoyé!");
        })
    }
    return (
        <>
            <Navbar />
            <ToastContainer />
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Faites votre Paiement hors ligne</h3>
                    </div>
                </div>
            </section>
            <section className="relative">
                <div className="container">
                   
                </div>
            </section>
            <div className="container-fluid relative">
               
            </div>
            <Footer />
        </>
    )
}